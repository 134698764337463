/* @font-face {
  font-family: ReemKufi;
  src: url("./assets/ReemKufi-Regular.ttf");
} */

@font-face {
  font-family: Fraunces;
  src: url("./assets/Fraunces/Fraunces-Regular.ttf");
}

@font-face {
  font-family: Fraunces;
  src: url("./assets/Fraunces/Fraunces-ExtraLight.ttf");
  font-weight: 100;
}

@font-face {
  font-family: "Avenir Next";
  src: url("./assets/AvenirNext/AvenirNext-UltraLight-11.ttf");
  font-weight: 200;
}

@font-face {
  font-family: "Avenir Next";
  src: url("./assets/AvenirNext/AvenirNext-Regular-08.ttf");
  font-weight: 400;
}

@font-face {
  font-family: "Avenir Next";
  src: url("./assets/AvenirNext/AvenirNext-Medium-06.ttf");
  font-weight: 500;
}

@font-face {
  font-family: "Avenir Next";
  src: url("./assets/AvenirNext/AvenirNext-DemiBold-03.ttf");
  font-weight: 600;
}

body {
  margin: 0;
  font-family: "Avenir Next";
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
}

button {
  font-family: "Avenir Next";
}
